import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e89a17be = () => interopDefault(import('../pages/the-project/index.vue' /* webpackChunkName: "pages/the-project/index" */))
const _3e459f46 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _1e0ed166 = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _0607cf73 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _67be7930 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _06092020 = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _66b0f857 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _3ecb258c = () => interopDefault(import('../pages/public-information/index.vue' /* webpackChunkName: "pages/public-information/index" */))
const _4e31009e = () => interopDefault(import('../pages/visitor-information/index.vue' /* webpackChunkName: "pages/visitor-information/index" */))
const _beb9d274 = () => interopDefault(import('../pages/magazine/index.vue' /* webpackChunkName: "pages/magazine/index" */))
const _d7b95432 = () => interopDefault(import('../pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _850e2cc8 = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _4fb2a53d = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _6e97bdfc = () => interopDefault(import('../pages/business-partners/index.vue' /* webpackChunkName: "pages/business-partners/index" */))
const _3674e10a = () => interopDefault(import('../pages/programs-in-varosliget/index.vue' /* webpackChunkName: "pages/programs-in-varosliget/index" */))
const _214d4907 = () => interopDefault(import('../pages/the-project/our-first-10-years.vue' /* webpackChunkName: "pages/the-project/our-first-10-years" */))
const _4724778e = () => interopDefault(import('../pages/the-project/swiper.vue' /* webpackChunkName: "pages/the-project/swiper" */))
const _7bbbf12a = () => interopDefault(import('../pages/press/media-library/index.vue' /* webpackChunkName: "pages/press/media-library/index" */))
const _0046994d = () => interopDefault(import('../pages/top-locations/balloon-fly.vue' /* webpackChunkName: "pages/top-locations/balloon-fly" */))
const _c39ad502 = () => interopDefault(import('../pages/top-locations/liget-budapest-visitor-center.vue' /* webpackChunkName: "pages/top-locations/liget-budapest-visitor-center" */))
const _528448c6 = () => interopDefault(import('../pages/top-locations/bird-friendly-learning-trail.vue' /* webpackChunkName: "pages/top-locations/bird-friendly-learning-trail" */))
const _8b9c70b4 = () => interopDefault(import('../pages/top-locations/house-of-hungarian-music.vue' /* webpackChunkName: "pages/top-locations/house-of-hungarian-music" */))
const _b2feb5fa = () => interopDefault(import('../pages/top-locations/house-of-the-hungarian-millennium.vue' /* webpackChunkName: "pages/top-locations/house-of-the-hungarian-millennium" */))
const _013ecc8a = () => interopDefault(import('../pages/top-locations/mocsenyi-mihaly-botanical-garden.vue' /* webpackChunkName: "pages/top-locations/mocsenyi-mihaly-botanical-garden" */))
const _55c43da2 = () => interopDefault(import('../pages/top-locations/museum-of-ethnography.vue' /* webpackChunkName: "pages/top-locations/museum-of-ethnography" */))
const _5d013c00 = () => interopDefault(import('../pages/top-locations/museum-of-fine-arts.vue' /* webpackChunkName: "pages/top-locations/museum-of-fine-arts" */))
const _8053d8ac = () => interopDefault(import('../pages/top-locations/playground-of-varosliget.vue' /* webpackChunkName: "pages/top-locations/playground-of-varosliget" */))
const _300e3372 = () => interopDefault(import('../pages/top-locations/varosliget-promenade.vue' /* webpackChunkName: "pages/top-locations/varosliget-promenade" */))
const _7c20672c = () => interopDefault(import('../pages/press/media-library/_gallerycategory/index.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/index" */))
const _08cdfb29 = () => interopDefault(import('../pages/press/media-library/_gallerycategory/_gallery.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/_gallery" */))
const _4d6adb5a = () => interopDefault(import('../pages/archive/_article.vue' /* webpackChunkName: "pages/archive/_article" */))
const _5ba9fb1d = () => interopDefault(import('../pages/business-partners/_partnerInfo.vue' /* webpackChunkName: "pages/business-partners/_partnerInfo" */))
const _a193d8b4 = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _fe778d1e = () => interopDefault(import('../pages/public-information/_data.vue' /* webpackChunkName: "pages/public-information/_data" */))
const _7dbfd0f3 = () => interopDefault(import('../pages/magazine/_article.vue' /* webpackChunkName: "pages/magazine/_article" */))
const _5a36c67a = () => interopDefault(import('../pages/press/_release.vue' /* webpackChunkName: "pages/press/_release" */))
const _e12d7168 = () => interopDefault(import('../pages/program-series/_programSeries.vue' /* webpackChunkName: "pages/program-series/_programSeries" */))
const _f0323ef4 = () => interopDefault(import('../pages/programs/_program.vue' /* webpackChunkName: "pages/programs/_program" */))
const _ffb4334a = () => interopDefault(import('../pages/the-project/_project.vue' /* webpackChunkName: "pages/the-project/_project" */))
const _634bff9c = () => interopDefault(import('../pages/visitor-information/_visitorInformation.vue' /* webpackChunkName: "pages/visitor-information/_visitorInformation" */))
const _04512e2b = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-projekt",
    component: _e89a17be,
    name: "the-project___hu___default"
  }, {
    path: "/adatvedelem",
    component: _3e459f46,
    name: "privacy-policy___hu___default"
  }, {
    path: "/archivum",
    component: _1e0ed166,
    name: "archive___hu___default"
  }, {
    path: "/en",
    component: _0607cf73,
    name: "index___en"
  }, {
    path: "/hirlevel-feliratkozas",
    component: _67be7930,
    name: "newsletter___hu___default"
  }, {
    path: "/hu",
    component: _0607cf73,
    name: "index___hu"
  }, {
    path: "/karrier",
    component: _06092020,
    name: "career___hu___default"
  }, {
    path: "/kereses",
    component: _66b0f857,
    name: "search___hu___default"
  }, {
    path: "/kozerdeku-adatok",
    component: _3ecb258c,
    name: "public-information___hu___default"
  }, {
    path: "/latogatas",
    component: _4e31009e,
    name: "visitor-information___hu___default"
  }, {
    path: "/magazin",
    component: _beb9d274,
    name: "magazine___hu___default"
  }, {
    path: "/sajto",
    component: _d7b95432,
    name: "press___hu___default"
  }, {
    path: "/szakmai-anyagok",
    component: _850e2cc8,
    name: "documents___hu___default"
  }, {
    path: "/terkep",
    component: _4fb2a53d,
    name: "map___hu___default"
  }, {
    path: "/uzleti-partnereinknek",
    component: _6e97bdfc,
    name: "business-partners___hu___default"
  }, {
    path: "/varosligeti-programok",
    component: _3674e10a,
    name: "programs-in-varosliget___hu___default"
  }, {
    path: "/en/archive",
    component: _1e0ed166,
    name: "archive___en"
  }, {
    path: "/en/business-partners",
    component: _6e97bdfc,
    name: "business-partners___en"
  }, {
    path: "/en/career",
    component: _06092020,
    name: "career___en"
  }, {
    path: "/en/hirlevel-feliratkozas",
    component: _67be7930,
    name: "newsletter___en"
  }, {
    path: "/en/kozerdeku-adatok",
    component: _3ecb258c,
    name: "public-information___en"
  }, {
    path: "/en/magazine",
    component: _beb9d274,
    name: "magazine___en"
  }, {
    path: "/en/map",
    component: _4fb2a53d,
    name: "map___en"
  }, {
    path: "/en/press",
    component: _d7b95432,
    name: "press___en"
  }, {
    path: "/en/privacy-policy",
    component: _3e459f46,
    name: "privacy-policy___en"
  }, {
    path: "/en/programs-in-varosliget",
    component: _3674e10a,
    name: "programs-in-varosliget___en"
  }, {
    path: "/en/search",
    component: _66b0f857,
    name: "search___en"
  }, {
    path: "/en/szakmai-anyagok",
    component: _850e2cc8,
    name: "documents___en"
  }, {
    path: "/en/the-project",
    component: _e89a17be,
    name: "the-project___en"
  }, {
    path: "/en/visitor-information",
    component: _4e31009e,
    name: "visitor-information___en"
  }, {
    path: "/hu/a-projekt",
    component: _e89a17be,
    name: "the-project___hu"
  }, {
    path: "/hu/adatvedelem",
    component: _3e459f46,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/archivum",
    component: _1e0ed166,
    name: "archive___hu"
  }, {
    path: "/hu/hirlevel-feliratkozas",
    component: _67be7930,
    name: "newsletter___hu"
  }, {
    path: "/hu/karrier",
    component: _06092020,
    name: "career___hu"
  }, {
    path: "/hu/kereses",
    component: _66b0f857,
    name: "search___hu"
  }, {
    path: "/hu/kozerdeku-adatok",
    component: _3ecb258c,
    name: "public-information___hu"
  }, {
    path: "/hu/latogatas",
    component: _4e31009e,
    name: "visitor-information___hu"
  }, {
    path: "/hu/magazin",
    component: _beb9d274,
    name: "magazine___hu"
  }, {
    path: "/hu/sajto",
    component: _d7b95432,
    name: "press___hu"
  }, {
    path: "/hu/szakmai-anyagok",
    component: _850e2cc8,
    name: "documents___hu"
  }, {
    path: "/hu/terkep",
    component: _4fb2a53d,
    name: "map___hu"
  }, {
    path: "/hu/uzleti-partnereinknek",
    component: _6e97bdfc,
    name: "business-partners___hu"
  }, {
    path: "/hu/varosligeti-programok",
    component: _3674e10a,
    name: "programs-in-varosliget___hu"
  }, {
    path: "/projekt/a-liget-projekt-elso-10-eve",
    component: _214d4907,
    name: "the-project-our-first-10-years___hu___default"
  }, {
    path: "/projekt/swiper",
    component: _4724778e,
    name: "the-project-swiper___hu___default"
  }, {
    path: "/sajto/mediatar",
    component: _7bbbf12a,
    name: "press-media-library___hu___default"
  }, {
    path: "/top-helyek/ballon-kilato",
    component: _0046994d,
    name: "top-locations-balloon-fly___hu___default"
  }, {
    path: "/top-helyek/liget-budapest-latogatokozpont",
    component: _c39ad502,
    name: "top-locations-liget-budapest-visitor-center___hu___default"
  }, {
    path: "/top-helyek/madarbarat-tanosveny",
    component: _528448c6,
    name: "top-locations-bird-friendly-learning-trail___hu___default"
  }, {
    path: "/top-helyek/magyar-zene-haza",
    component: _8b9c70b4,
    name: "top-locations-house-of-hungarian-music___hu___default"
  }, {
    path: "/top-helyek/millennium-haza-neo-kortars-muveszeti-ter",
    component: _b2feb5fa,
    name: "top-locations-house-of-the-hungarian-millennium___hu___default"
  }, {
    path: "/top-helyek/mocsenyi-mihaly-botanikus-kert",
    component: _013ecc8a,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___hu___default"
  }, {
    path: "/top-helyek/neprajzi-muzeum",
    component: _55c43da2,
    name: "top-locations-museum-of-ethnography___hu___default"
  }, {
    path: "/top-helyek/szepmuveszeti-muzeum",
    component: _5d013c00,
    name: "top-locations-museum-of-fine-arts___hu___default"
  }, {
    path: "/top-helyek/varosligeti-nagyjatszoter",
    component: _8053d8ac,
    name: "top-locations-playground-of-varosliget___hu___default"
  }, {
    path: "/top-helyek/varosligeti-promenad",
    component: _300e3372,
    name: "top-locations-varosliget-promenade___hu___default"
  }, {
    path: "/en/press/media",
    component: _7bbbf12a,
    name: "press-media-library___en"
  }, {
    path: "/en/the-project/our-first-10-years",
    component: _214d4907,
    name: "the-project-our-first-10-years___en"
  }, {
    path: "/en/the-project/swiper",
    component: _4724778e,
    name: "the-project-swiper___en"
  }, {
    path: "/en/top-locations/balloon-fly",
    component: _0046994d,
    name: "top-locations-balloon-fly___en"
  }, {
    path: "/en/top-locations/bird-friendly-learning-trail",
    component: _528448c6,
    name: "top-locations-bird-friendly-learning-trail___en"
  }, {
    path: "/en/top-locations/house-of-hungarian-music",
    component: _8b9c70b4,
    name: "top-locations-house-of-hungarian-music___en"
  }, {
    path: "/en/top-locations/house-of-the-hungarian-millennium",
    component: _b2feb5fa,
    name: "top-locations-house-of-the-hungarian-millennium___en"
  }, {
    path: "/en/top-locations/liget-budapest-visitor-center",
    component: _c39ad502,
    name: "top-locations-liget-budapest-visitor-center___en"
  }, {
    path: "/en/top-locations/mocsenyi-mihaly-botanical-garden",
    component: _013ecc8a,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___en"
  }, {
    path: "/en/top-locations/museum-of-ethnography",
    component: _55c43da2,
    name: "top-locations-museum-of-ethnography___en"
  }, {
    path: "/en/top-locations/museum-of-fine-arts",
    component: _5d013c00,
    name: "top-locations-museum-of-fine-arts___en"
  }, {
    path: "/en/top-locations/playground-of-varosliget",
    component: _8053d8ac,
    name: "top-locations-playground-of-varosliget___en"
  }, {
    path: "/en/top-locations/varosliget-promenade",
    component: _300e3372,
    name: "top-locations-varosliget-promenade___en"
  }, {
    path: "/hu/projekt/a-liget-projekt-elso-10-eve",
    component: _214d4907,
    name: "the-project-our-first-10-years___hu"
  }, {
    path: "/hu/projekt/swiper",
    component: _4724778e,
    name: "the-project-swiper___hu"
  }, {
    path: "/hu/sajto/mediatar",
    component: _7bbbf12a,
    name: "press-media-library___hu"
  }, {
    path: "/hu/top-helyek/ballon-kilato",
    component: _0046994d,
    name: "top-locations-balloon-fly___hu"
  }, {
    path: "/hu/top-helyek/liget-budapest-latogatokozpont",
    component: _c39ad502,
    name: "top-locations-liget-budapest-visitor-center___hu"
  }, {
    path: "/hu/top-helyek/madarbarat-tanosveny",
    component: _528448c6,
    name: "top-locations-bird-friendly-learning-trail___hu"
  }, {
    path: "/hu/top-helyek/magyar-zene-haza",
    component: _8b9c70b4,
    name: "top-locations-house-of-hungarian-music___hu"
  }, {
    path: "/hu/top-helyek/millennium-haza-neo-kortars-muveszeti-ter",
    component: _b2feb5fa,
    name: "top-locations-house-of-the-hungarian-millennium___hu"
  }, {
    path: "/hu/top-helyek/mocsenyi-mihaly-botanikus-kert",
    component: _013ecc8a,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___hu"
  }, {
    path: "/hu/top-helyek/neprajzi-muzeum",
    component: _55c43da2,
    name: "top-locations-museum-of-ethnography___hu"
  }, {
    path: "/hu/top-helyek/szepmuveszeti-muzeum",
    component: _5d013c00,
    name: "top-locations-museum-of-fine-arts___hu"
  }, {
    path: "/hu/top-helyek/varosligeti-nagyjatszoter",
    component: _8053d8ac,
    name: "top-locations-playground-of-varosliget___hu"
  }, {
    path: "/hu/top-helyek/varosligeti-promenad",
    component: _300e3372,
    name: "top-locations-varosliget-promenade___hu"
  }, {
    path: "/en/press/media/:gallerycategory",
    component: _7c20672c,
    name: "press-media-library-gallerycategory___en"
  }, {
    path: "/hu/sajto/mediatar/:gallerycategory",
    component: _7c20672c,
    name: "press-media-library-gallerycategory___hu"
  }, {
    path: "/en/press/media/:gallerycategory/:gallery",
    component: _08cdfb29,
    name: "press-media-library-gallerycategory-gallery___en"
  }, {
    path: "/hu/sajto/mediatar/:gallerycategory/:gallery",
    component: _08cdfb29,
    name: "press-media-library-gallerycategory-gallery___hu"
  }, {
    path: "/en/archive/:article",
    component: _4d6adb5a,
    name: "archive-article___en"
  }, {
    path: "/en/business-partners/:partnerInfo",
    component: _5ba9fb1d,
    name: "business-partners-partnerInfo___en"
  }, {
    path: "/en/career/:position",
    component: _a193d8b4,
    name: "career-position___en"
  }, {
    path: "/en/kozerdeku-adatok/:data",
    component: _fe778d1e,
    name: "public-information-data___en"
  }, {
    path: "/en/magazine/:article",
    component: _7dbfd0f3,
    name: "magazine-article___en"
  }, {
    path: "/en/press/:release",
    component: _5a36c67a,
    name: "press-release___en"
  }, {
    path: "/en/program-series/:programSeries",
    component: _e12d7168,
    name: "program-series-programSeries___en"
  }, {
    path: "/en/programs/:program",
    component: _f0323ef4,
    name: "programs-program___en"
  }, {
    path: "/en/the-project/:project",
    component: _ffb4334a,
    name: "the-project-project___en"
  }, {
    path: "/en/visitor-information/:visitorInformation",
    component: _634bff9c,
    name: "visitor-information-visitorInformation___en"
  }, {
    path: "/hu/archivum/:article",
    component: _4d6adb5a,
    name: "archive-article___hu"
  }, {
    path: "/hu/karrier/:position",
    component: _a193d8b4,
    name: "career-position___hu"
  }, {
    path: "/hu/kozerdeku-adatok/:data",
    component: _fe778d1e,
    name: "public-information-data___hu"
  }, {
    path: "/hu/latogatas/:visitorInformation",
    component: _634bff9c,
    name: "visitor-information-visitorInformation___hu"
  }, {
    path: "/hu/magazin/:article",
    component: _7dbfd0f3,
    name: "magazine-article___hu"
  }, {
    path: "/hu/programok/:program",
    component: _f0323ef4,
    name: "programs-program___hu"
  }, {
    path: "/hu/programsorozatok/:programSeries",
    component: _e12d7168,
    name: "program-series-programSeries___hu"
  }, {
    path: "/hu/projekt/:project",
    component: _ffb4334a,
    name: "the-project-project___hu"
  }, {
    path: "/hu/sajto/:release",
    component: _5a36c67a,
    name: "press-release___hu"
  }, {
    path: "/hu/uzleti-partnereinknek/:partnerInfo",
    component: _5ba9fb1d,
    name: "business-partners-partnerInfo___hu"
  }, {
    path: "/sajto/mediatar/:gallerycategory",
    component: _7c20672c,
    name: "press-media-library-gallerycategory___hu___default"
  }, {
    path: "/sajto/mediatar/:gallerycategory/:gallery",
    component: _08cdfb29,
    name: "press-media-library-gallerycategory-gallery___hu___default"
  }, {
    path: "/archivum/:article",
    component: _4d6adb5a,
    name: "archive-article___hu___default"
  }, {
    path: "/en/:slug",
    component: _04512e2b,
    name: "slug___en"
  }, {
    path: "/hu/:slug",
    component: _04512e2b,
    name: "slug___hu"
  }, {
    path: "/karrier/:position",
    component: _a193d8b4,
    name: "career-position___hu___default"
  }, {
    path: "/kozerdeku-adatok/:data",
    component: _fe778d1e,
    name: "public-information-data___hu___default"
  }, {
    path: "/latogatas/:visitorInformation",
    component: _634bff9c,
    name: "visitor-information-visitorInformation___hu___default"
  }, {
    path: "/magazin/:article",
    component: _7dbfd0f3,
    name: "magazine-article___hu___default"
  }, {
    path: "/programok/:program",
    component: _f0323ef4,
    name: "programs-program___hu___default"
  }, {
    path: "/programsorozatok/:programSeries",
    component: _e12d7168,
    name: "program-series-programSeries___hu___default"
  }, {
    path: "/projekt/:project",
    component: _ffb4334a,
    name: "the-project-project___hu___default"
  }, {
    path: "/sajto/:release",
    component: _5a36c67a,
    name: "press-release___hu___default"
  }, {
    path: "/uzleti-partnereinknek/:partnerInfo",
    component: _5ba9fb1d,
    name: "business-partners-partnerInfo___hu___default"
  }, {
    path: "/",
    component: _0607cf73,
    name: "index___hu___default"
  }, {
    path: "/:slug",
    component: _04512e2b,
    name: "slug___hu___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
